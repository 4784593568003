export const convertedStatus = [
    {
        label: 'New',
        color: ' bg-success text-white '
    },
    {
        label: 'New',
        color: ' bg-success text-white '
    },
    {
        label: 'New',
        color: ' bg-success text-white '
    },
    {
        label: 'New',
        color: ' bg-success text-white '
    },
]